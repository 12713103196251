import { RiMenuLine } from "@remixicon/react";

const navItems = [
  { name: "Features", href: "/#features" },
  // { name: "Pricing", href: "/pricing" },
  { name: "Help", href: "/help" },
  { name: "Blog", href: "/blog" },
  { name: "Changelog", href: "/changelog" },
];

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const MobileMenu = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <RiMenuLine className="text-primary/80 w-5 h-5 ml-3 mt-1" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-40">
        {navItems.map((item, index) => (
          <DropdownMenuItem key={index} asChild>
            <a href={item.href}>{item.name}</a>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { MobileMenu, navItems };
